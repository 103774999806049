import { ReactComponent as AppLogo } from '../../assets/logos/app_logo.svg';

export const SplashScreen = ({ message }: { message?: React.ReactNode }) => {
  return (
    <div className='splash-screen'>
      <AppLogo />
      <h1 className='title'>Watercooler Chat</h1>
      {message}
    </div>
  );
};
