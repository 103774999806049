import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { StreamTokenStorage } from '../context/ChatServices/streamTokenStorage';

export const useLogout = () => {
  const { logout } = useAuth0();

  return useCallback(() => {
    StreamTokenStorage.clearToken();
    logout({ returnTo: `${window.location.origin}/auth` });
  }, []);
};
