import { useEffect } from 'react';
import { ChannelList, useChatContext, SearchInput, SearchInputProps } from 'stream-chat-react';
import { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import { useParams, useNavigate } from 'react-router-dom';

import { MainMenu } from '../Menu';
import { Arrow } from '../common';

const DEFAULT_CHANNEL = 'general';

const CustomSearchInput = (props: SearchInputProps) => {
  const { closeMobileNav } = useChatContext();
  return (
    <div className='search-wrapper'>
      <MainMenu />
      <SearchInput {...props} />
      <button onClick={() => closeMobileNav()} className='close-mobile-nav-btn'>
        <Arrow pointTo='right' />
      </button>
    </div>
  );
};

export const Sidebar = () => {
  const { client, channel, setActiveChannel } = useChatContext();
  const { channelId } = useParams();
  const navigate = useNavigate();

  const filters: ChannelFilters = { type: 'messaging', members: { $in: [client.user?.id || ''] } };
  const options: ChannelOptions = { state: true, presence: true, limit: 10 };
  const sort: ChannelSort = { last_message_at: -1, updated_at: -1 };

  // set the correct param when clicked on channel in the list
  useEffect(() => {
    if (!channel?.id || channel.id === channelId) return;

    navigate({ pathname: `/${channel.id}` });
  }, [channel?.id]);

  // set active channel only if URL param changed
  useEffect(() => {
    if (!channelId) return navigate(`/${DEFAULT_CHANNEL}`);

    if (channel?.id === channelId) return;

    setActiveChannel(client.channel('messaging', channelId));
  }, [channelId]);

  return (
    <div className='wc-sidebar'>
      <ChannelList
        additionalChannelSearchProps={{
          searchForChannels: true,
          SearchInput: CustomSearchInput,
        }}
        setActiveChannelOnMount={false}
        filters={filters}
        sort={sort}
        options={options}
        showChannelSearch
      />
    </div>
  );
};
