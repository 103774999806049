export const METADATA_URL = 'https://watercooler.getstream.io/metadata';

export type StreamUserPayload = {
  stream_user_id: string;
};

export type UserPayload = {
  id: string;
  [METADATA_URL]: StreamUserPayload;
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  locale: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
};
