import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import { SplashScreen } from '../SplashScreen';
import { ChildrenOnly } from '../../types';
import { Messages } from '../SplashScreen/Messages';

export const RequireAuth = ({ children }: ChildrenOnly) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  if (isLoading) return <SplashScreen message={Messages.AUTHENTICATING} />;

  if (!isAuthenticated) {
    return <Navigate to='/auth' state={{ from: location }} replace />;
  }

  return children;
};
