import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Auth } from './Auth';
import { ChatUI } from './ChatUI';
import { RequireAuth, LoggedOutOnly } from '../components/Auth';
import { ChatServicesWrapper } from '../context/ChatServices';

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path='/auth'
        element={
          <LoggedOutOnly>
            <Auth />
          </LoggedOutOnly>
        }
      />
      <Route
        path='/*'
        element={
          <RequireAuth>
            <ChatServicesWrapper>
              <Routes>
                <Route path=':channelId' element={<ChatUI />} />
                <Route path='*' element={<ChatUI />} />
              </Routes>
            </ChatServicesWrapper>
          </RequireAuth>
        }
      />
    </Routes>
  </BrowserRouter>
);
