import { useCallback } from 'react';
import {
  Channel,
  ChannelHeader,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChatContext,
  CustomMentionHandler,
  MessageInputFlat,
} from 'stream-chat-react';

import { useNavigate } from 'react-router-dom';
import { ChannelHeaderAvatar } from '../components/Avatar';
import { Sidebar } from '../components/Sidebar/Sidebar';
import { Arrow } from '../components/common';

const LoadingChannelIndicator = () => (
  <div className='str-chat str-chat-channel--loading'>
    <LoadingIndicator size={50} />
  </div>
);

export const ChatUI = () => {
  const navigate = useNavigate();
  const { client } = useChatContext();

  const handleMentionClick: CustomMentionHandler = useCallback(
    async (event, users) => {
      if (event.target?.tagName !== 'SPAN' || !event.target?.textContent) return;

      const name = event.target.textContent.replace(/@|\s+/g, ' ').trim();

      const user = users.find((u) => u.name === name);

      if (!user || !client.user?.id) return;

      const channel = await client
        .channel('messaging', { members: [user.id, client.user.id] })
        .create();

      navigate(`/${channel.channel.id}`);
    },
    [client],
  );

  return (
    <>
      <Sidebar />
      <Channel LoadingIndicator={LoadingChannelIndicator}>
        <Window>
          {/* FIXME:
          super hacky hack by setting invalid URL and using custom Avatar component to display
          channel image placeholder
          */}
          <ChannelHeader
            // FIXME: remove anonymous function once the typing in ChannelHeader is fixed
            MenuIcon={() => <Arrow pointTo='left' />}
            Avatar={ChannelHeaderAvatar}
            image='_'
          />
          <MessageList onMentionsClick={handleMentionClick} />
          <MessageInput focus grow />
        </Window>
        <Thread Input={MessageInputFlat} additionalMessageInputProps={{ grow: true }} />
      </Channel>
    </>
  );
};
