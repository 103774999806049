import { useLogout } from '../../hooks';

const SplashScreenErrorMessage = () => {
  const logout = useLogout();

  return (
    <>
      <p>Could not connect to the chat service, please contact support</p>
      <button className='button' onClick={logout}>
        Back to login page
      </button>
    </>
  );
};

export const Messages: Record<string, React.ReactNode> = {
  LOADING: 'Loading...',
  CONNECTING: 'Connecting to the chat service...',
  ERROR: <SplashScreenErrorMessage />,
  AUTHENTICATING: 'Authenticating...',
};
