import { ReactComponent as SVGArrow } from '../../assets/icons/arrow.svg';

export const arrowRotationMap = {
  // degrees
  left: 0,
  right: 180,
  up: 90,
  down: -90,
};

export type ArrowProps = {
  pointTo?: 'up' | 'down' | 'left' | 'right';
  /**
   * Will be used over `pointTo` if specified.
   */
  degrees?: number;
};

export const Arrow = ({ pointTo = 'left', degrees }: ArrowProps) => {
  const rotateBy = degrees ?? arrowRotationMap[pointTo];

  return <SVGArrow style={{ transform: `rotate(${rotateBy}deg)` }} />;
};
