import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { ChildrenOnly } from '../../types';

export const LoggedOutOnly = ({ children }: ChildrenOnly) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to='/' />;
  }
  return children;
};
