import Gleap from 'gleap';
import { Auth0Provider } from '@auth0/auth0-react';
import { Router } from './pages';
import { themeSelector, useChatServicesStore } from './hooks';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_STREAM_API_KEY,
  REACT_APP_AUTH0_CALLBACK_URI,
  REACT_APP_BUG_REPORTER_API_KEY,
} = process.env;

const Root = () => {
  const [theme] = useChatServicesStore(themeSelector);

  if (!REACT_APP_AUTH0_DOMAIN) {
    throw new Error('ENV: Missing Auth0 domain');
  }

  if (!REACT_APP_AUTH0_CLIENT_ID) {
    throw new Error('ENV: Missing Auth0 Client ID');
  }

  if (!REACT_APP_STREAM_API_KEY) {
    throw new Error('ENV: Missing Stream API Key');
  }

  // bug reporting tool init (https://gleap.io/docs/javascript/)
  if (REACT_APP_BUG_REPORTER_API_KEY) {
    Gleap.initialize(REACT_APP_BUG_REPORTER_API_KEY);
  }

  return (
    <div className={theme}>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin + REACT_APP_AUTH0_CALLBACK_URI}
        useRefreshTokens={true}
        cacheLocation='localstorage' // TODO: replace later on
      >
        <Router />
      </Auth0Provider>
    </div>
  );
};

export default Root;
