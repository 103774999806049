import { PropsWithChildren, forwardRef, DetailedHTMLProps, LiHTMLAttributes } from 'react';

export const DropdownMenu = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    isOpen?: boolean;
  }>
>(({ children, isOpen }, ref) => (
  <div ref={ref} className='dropdown-anchor'>
    {isOpen && <ul className='dropdown-list'>{children}</ul>}
  </div>
));

DropdownMenu.displayName = 'DropdownMenu';

export const DropdownItem = (
  props: DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>,
) => <li {...props} />;
