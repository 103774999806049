import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { StreamTokenStorage } from '../context/ChatServices';
import { hasTokenExpired } from '../utils';

const { REACT_APP_PROVISIONING_SERVER_URL: serverURL } = process.env;

const getStreamToken = async (idToken?: string) => {
  const { token: streamToken } = StreamTokenStorage;

  if (streamToken && !hasTokenExpired(streamToken)) return streamToken;

  // if previous condition did not return the token from storage
  // and the idToken is not specified, return null and proceed
  // with the refresh process
  // (used for when the user refreshes the page,
  // no need to generate new token if the stored one is valid)
  if (!idToken) return null;

  // remove token from storage in case something goes wrong
  // with the new request
  if (streamToken) StreamTokenStorage.clearToken();

  const { stream_token: newStreamToken } = (await fetch(`${serverURL}/token`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  }).then((r) => r.json())) as { stream_token: string };

  StreamTokenStorage.token = newStreamToken;

  return newStreamToken;
};

export const useStreamTokenProvider = () => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  // TODO: refresh only if window active (visibilitychange event)

  return useCallback(async () => {
    // try storage streamToken first
    const storedStreamToken = await getStreamToken();

    if (storedStreamToken) return storedStreamToken;

    // try to return claims if cached
    // if not, call getAccessTokenSilently which refreshes Auth0 token
    // and sets new claims which need to be accessed again
    let claims = await getIdTokenClaims();

    // Auth0 token expired
    if (!claims?.__raw) {
      // refresh Auth0 token with getAccessTokenSilently
      await getAccessTokenSilently();
      // get fresh claims
      claims = await getIdTokenClaims();
    }
    const idToken = claims!.__raw;

    return (await getStreamToken(idToken)) as string;
  }, []);
};
