import create from 'zustand';

type Theme = 'light' | 'dark';

export type ChatServicesStore = {
  theme: Theme;
  setTheme: (t: Theme) => void;
};

export const useChatServicesStore = create<ChatServicesStore>((set) => ({
  theme: (window.localStorage.getItem('theme') as Theme) ?? 'light',
  // FIXME: replace hard-coded "light" with system theme matcher once dark mode styling is fixed
  // (window?.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
  setTheme: (newTheme: Theme) => {
    window.localStorage.setItem('theme', newTheme);
    set({ theme: newTheme });
  },
}));

export const themeSelector = (state: ChatServicesStore) =>
  [state.theme, state.setTheme] as [string, ChatServicesStore['setTheme']];
