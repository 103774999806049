import { StreamChat } from 'stream-chat';

const {
  REACT_APP_STREAM_API_KEY: streamApiKey,
  REACT_APP_CHAT_SERVER_ENDPOINT: chatServerEndpoint,
} = process.env;

export const chatAPIClient = StreamChat.getInstance(streamApiKey, {
  baseURL: chatServerEndpoint || undefined,
});
