import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as GoogleLogo } from '../../assets/logos/google_logo.svg';
import { ReactComponent as AppLogo } from '../../assets/logos/app_logo.svg';
import { ReactComponent as StreamLogo } from '../../assets/logos/stream_logo.svg';

const { REACT_APP_NODE_ENV, REACT_APP_AUTH0_CONNECTION } = process.env;

export const Authenticator = () => {
  const { loginWithRedirect } = useAuth0();
  const connection = REACT_APP_NODE_ENV === 'test' ? '' : REACT_APP_AUTH0_CONNECTION;

  return (
    <div className='wc-auth-page-content'>
      <div className='wc-authenticator-box' data-testid='authenticator-box'>
        <div className='wc-welcome'>
          <AppLogo />
          <h1>Water Cooler Chat</h1>
          <h2>Welcome!</h2>
        </div>
        <button
          className='wc-login-btn'
          data-testid='login-btn'
          onClick={() => loginWithRedirect({ connection })}
        >
          <GoogleLogo />
          Sign in with Google
        </button>
      </div>
      <footer className='wc-authenticator-footer' data-testid='authenticator-footer'>
        <div className='wc-authenticator-footer-link-group'>
          <a href='#'>Help</a>
          <a href='#'>Privacy</a>
          <a href='#'>Terms</a>
        </div>
        <StreamLogo />
      </footer>
    </div>
  );
};
