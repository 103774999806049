const STORAGE_KEY = 'stream_token';

export class StreamTokenStorage {
  static get token(): string | null {
    return window.localStorage.getItem(STORAGE_KEY);
  }

  static set token(newToken: string | null) {
    if (!newToken?.length) return;
    window.localStorage.setItem(STORAGE_KEY, newToken);
  }

  static clearToken() {
    window.localStorage.removeItem(STORAGE_KEY);
  }
}
