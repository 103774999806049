import { Avatar as StreamAvatar, AvatarProps } from 'stream-chat-react';

export const ChannelPreviewAvatar = (props: AvatarProps) => (
  <StreamAvatar name='#' {...props} size={49} shape='rounded' />
);

export const MessageAvatar = (props: AvatarProps) => (
  <StreamAvatar {...props} size={36} shape='rounded' />
);

export const ChannelHeaderAvatar = (props: AvatarProps) => (
  <StreamAvatar name='#' {...props} size={40} shape='rounded' />
);
