import { useEffect, useRef, useState } from 'react';
import { Chat } from 'stream-chat-react';
import { useAuth0 } from '@auth0/auth0-react';

import { chatAPIClient } from './';
import { SplashScreen, Messages } from '../../components/SplashScreen';
import { themeSelector, useChatServicesStore, useStreamTokenProvider } from '../../hooks';
import { ChildrenOnly, METADATA_URL, UserPayload } from '../../types';

export const ChatServicesWrapper = ({ children }: ChildrenOnly) => {
  const { user } = useAuth0<UserPayload>();

  const [theme] = useChatServicesStore(themeSelector);

  const streamTokenProvider = useStreamTokenProvider();

  const [message, setMessage] = useState<React.ReactNode | null>(Messages.LOADING);

  // FIXME: ???
  // when dependency changes, cleaning function gets called but does not
  // wait for disconnectUser completion (obviously) and calls effect callback
  // which crashes because disconnectUser did not finish
  // happens mainly in development, "state" dependency is only for testing
  // purposes but still an issue worth noting I think
  const disconnectReference = useRef<Promise<void>>(Promise.resolve());

  useEffect(() => {
    if (!user) return;

    disconnectReference.current.then(() => {
      setMessage(Messages.CONNECTING);

      const timeout = setTimeout(() => {
        setMessage(Messages.ERROR);
      }, 10000);

      chatAPIClient
        .connectUser({ id: user[METADATA_URL].stream_user_id }, streamTokenProvider)
        .catch((error) => {
          setMessage(Messages.ERROR);
          console.error(error);
        })
        .then(() => {
          clearTimeout(timeout);
        });

      chatAPIClient.on('connection.changed', ({ online: isOnline }) => {
        // setMessage(isOnline ? null : Messages.ERROR);
        if (isOnline) setMessage(null);
      });
    });

    return () => {
      disconnectReference.current = chatAPIClient.disconnectUser();
    };
  }, [user]);

  if (message) return <SplashScreen message={message} />;

  // TODO: should deprecate theme in Chat props
  return (
    <Chat client={chatAPIClient} theme={`messaging ${theme}`}>
      {children}
    </Chat>
  );
};
