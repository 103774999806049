// jsonwebtoken.decode requires polyfills so I used this one to replace it
export const decode = (jwt: string) => {
  const [, payload] = jwt.split('.') as [string, string, string];

  if (!payload) return null;
  return JSON.parse(window.atob(payload));
};

export const hasTokenExpired = (jwt: string | null) => {
  if (!jwt) return true;

  const { exp } = decode(jwt) as { exp: number };

  if (!exp) return true;

  return Date.now() >= exp * 1000;
};
